/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";

// @mui material components
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDDropzone from "components/MDDropzone";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "utils/DataTable";

// Data
import { fetch, patch, field, URL } from "layouts/applications/data-request/data/tableData";
import { baseUrlApi } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

function DataRequestWithdrawal() {
  const { getItem } = useLocalStorage();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});

  const [transferDt, setTransferDt] = useState("");
  const [fileName, setFileName] = useState("");
  const [clickable, setClickable] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);

  const setParamOpenDialog = (value) => {
    setParam(value);
    setFileName("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirm = async () => {
    const result = await patch(param.id);
    handleClose();
    handleCloseConfirm();
    setFetchTrigger((val) => val + 1);
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(async ({ pageIndex, pageSize, globalFilter }) => {
    setLoading(true);

    const skip = pageSize * pageIndex;
    const limit = skip + pageSize;
    const result = await fetch({ skip, limit }, globalFilter);
    setData(
      result.data.map((el) => ({
        ...el,
        action: {
          id: el.id,
          detail: {
            status: el.status,
            total: el.total,
          },
        },
      }))
    );
    setTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
    setLoading(false);
  }, []);
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Request Withdrawal
            </MDTypography>
            <MDTypography variant="button" color="text">
              List of All Request Withdrawal.
            </MDTypography>
          </MDBox>
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
          <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle>Approve Request Withdrawal</DialogTitle>
            <DialogContent>
              <Stack direction="column" spacing={3}>
                <MDBox pt={1} pb={1}>
                  <MDDatePicker
                    input={{
                      id: "transferDt",
                      label: "Transfer Date",
                      placeholder: "Select Transfer Date",
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                    }}
                    options={{
                      maxDate: now,
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:S",
                      time_24hr: true,
                      defaultDate: now,
                      defaultHour: currentHour,
                      defaultMinute: currentMinute,
                      onChange: (selectedDates, dateStr, instance) => {
                        setTransferDt(dateStr);
                        setClickable(!!dateStr);
                      },
                    }}
                    value={transferDt}
                  />
                </MDBox>
                <MDBox pt={1} pb={1}>
                  <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Bukti Transfer
                    </MDTypography>
                  </MDBox>
                  {useMemo(
                    () => (
                      <MDDropzone
                        options={{
                          url: `${baseUrlApi}${URL}/${param.id}`,
                          headers: { Authorization: `Bearer ${getItem("token")}` },
                          params: {
                            transferDt,
                          },
                          clickable,
                          acceptedFiles: "image/jpeg,image/png",
                          success(file, response) {
                            setFileName(file.name);
                          },
                        }}
                      />
                    ),
                    [clickable]
                  )}
                </MDBox>
              </Stack>
            </DialogContent>
            <DialogActions>
              <MDButton variant="text" color="error" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton
                variant="text"
                color="info"
                disabled={!transferDt || !fileName}
                onClick={handleSave}
              >
                Save
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth maxWidth="xs" open={openConfirm} onClose={handleCloseConfirm}>
            <DialogTitle>Konfirmasi</DialogTitle>
            <DialogContent>
              <DialogContentText>Apakah Anda yakin melakukan proses ini?</DialogContentText>
              <DialogActions>
                <MDButton variant="text" color="error" onClick={handleCloseConfirm}>
                  Cancel
                </MDButton>
                <MDButton variant="text" color="info" onClick={handleConfirm}>
                  Confirm
                </MDButton>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataRequestWithdrawal;
